import { configureStore } from '@reduxjs/toolkit';
import loginReducer from "../components/login/loginSlice";
import battleReducer from "../components/BastardBattle/bastardbattleSlice"; 
import wallReducer from "../components/BeyondWall/BeyondWallSlice"; 
import timerReducer from "../components/timer/timerSlice"; 
import sacrificeReducer from "../components/sacrifice/sacrificeSlice"; 
import finalReducer from "../components/final/finalSlice"; 
import weddingReducer from "../components/RedWedding/redweddingSlice";

export default configureStore({
  reducer: {
    login: loginReducer, 
    battle: battleReducer, 
    wall: wallReducer, 
    timer: timerReducer, 
    sacrifice: sacrificeReducer, 
    final: finalReducer,
    wedding: weddingReducer
  },
});
