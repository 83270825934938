import { createSlice } from '@reduxjs/toolkit';

export const loginSlice = createSlice({
  name: 'login',
  initialState: {
    name: "",
    room: "", 
    users: [],
    enter: false,
    solved: []
  },
  reducers: {
    setName: (state, action) => { 
        state.name = action.payload;
    }, 
    setRoom: (state, action) => { 
        state.room = action.payload
    }, 
    setUsers: (state, action) => { 
        state.users = action.payload
    }, 
    setSolved: (state, action) => { 
        state.solved = action.payload
    }, 
    entered: (state)=> { 
      state.enter = true
    }
  },
});

export const { setName, setRoom, setUsers, setSolved, entered } = loginSlice.actions;



export default loginSlice.reducer;
