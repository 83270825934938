import React from "react"; 
import {Container, Row, Col} from "react-bootstrap"; 
import Valar from "../photos/loginscreen.jpg";
import "./congrats.css";


function Congrats() { 



    return ( 
        <Container className = "base congrats" fluid style = {{backgroundImage: `url(${Valar})`}}>
            <Row>
                <Col md = {12}>
                <div className = "script">
                <p> Heyyy Sohi,</p>

<p> Happy Birthday yaar (or should I say Asshekhqoyi vezhvena). I know this isn’t a SUPER personal birthday present,
     but I thought GoT was fitting and easy enough for you to be Jon Snow (as clueless of an idiot as him) for the hour or 
     less that it takes you. Insert some cliche message about you being my sun and stars, and I, the moon of your life.
      Insert some additional GoT references that are fitting for us (ohhh, remember that one time I cleverly said “she, the 
      girl who has no name” at the apartment, at uhhh, a certain time, at a certain place lol). Anyways, besides the point - 
      Like I said, I know its not super personal and not pertaining to us, but I hope you still enjoyed the gift. I’ll save
       the more personal gifts for another time, so I don’t exhaust my options :)</p>

<p> Anyways, love you jaanu. Happy Birthday  - and pakka, this is the last of the gifts. I’ll try to be cuter in two months :) </p>

<p> P.S. you’re welcome for making this message sorta short and not super duper senti! </p>
            </div>
                </Col>
            </Row>

            
        </Container>
    )
}


export default Congrats; 