import React, {useState} from "react"; 
import {Container, Row, Col, Button} from "react-bootstrap"; 
import { SwitchTransition, CSSTransition} from "react-transition-group"
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import wall from "../photos/beyondwall.jpg";
import finalclue from "../photos/finalclues/fclue2.png"
import symbols from "../photos/symbols.png"; 
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; 
import { faArrowRight, faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import "./BeyondWall.css";
import socket from "../socket"; 

function BeyondWall({globanswer, solved}) { 
    const [number, setNumber] = useState(0);
    const [answer, setAnswer] = useState(""); 

    function Submit() { 
        if (answer.toLowerCase() == "winter") { 
            socket.emit("solved", 0)
        } else{ 
            setAnswer("")
        }
    }

    function changeAnswer(value) { 
        socket.emit("wall", value); 
        setAnswer(value); 
    }

    const intro = (<div className = "script"> <p> Jon Snow- your many sins have caused pain to even those you love. Ygritte went down a martyr 
            at Castle Black, and you now a rightful Lord Commander - but at what expense?
            Your companionship to the Wildlings, your voice of reason, her love diminished. Do you have 
            what it takes to set this right, and escape a sin from purgatory?
            </p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/tLuNG9jUUwI?controls=0&showinfo=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" controls="0" allowfullscreen></iframe>
            </div> )
    const puzzle = ( 
        <div className = "puzzle">
        <img src = {symbols}/> 
        <div className = "input">
            <input 
                type = "text" 
                value = {globanswer}
                onChange = {(event)=> changeAnswer(event.target.value)}
            />
            <Button onClick = {()=> Submit()}> Submit </Button>
        </div>
        </div>)

    const slides = [intro, puzzle]
    if (solved[0]) { 
        return (
            <Container className = "base wall" fluid style = {{backgroundImage: `url(${wall})`}}>
            <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
             <div className = "finalclue">
                 <img src = {finalclue} />
             </div>
         </Container>
        )
    } else { 
        return ( 
            <Container className = "base wall" fluid style = {{backgroundImage: `url(${wall})`}}>
                <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
            <SwitchTransition mode = "out-in">
                    <CSSTransition
                        key = {number}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames = "fade"
                    >
                        <div> 
                        <Button className = {number == 0 ? "arrow right" : "arrow left"} onClick = {()=> setNumber(1 - number)}>
                                {number == 0 ? <FontAwesomeIcon icon = {faArrowRight} /> : <FontAwesomeIcon icon = {faArrowLeft} />}
                        </Button>
                            {slides[number]}
                        </div>
                        </CSSTransition> 
            </SwitchTransition>
            </Container>
        )
    }



}; 

const mapStateToProps = state => ({ 
    globanswer: state.wall.answer,
    solved: state.login.solved
})

export default connect(mapStateToProps, null)(BeyondWall); 