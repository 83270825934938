import {createSlice} from "@reduxjs/toolkit"; 


export const weddingSlice = createSlice({
    name: "wedding", 
    initialState: { 
        answer: ""
    }, 
    reducers: { 
        changeAnswer: (state, action) => {
            console.log(state.answer)
            console.log(action.payload)
            state.answer = action.payload; 
        }
    }

})

export const {changeAnswer} = weddingSlice.actions; 

export default weddingSlice.reducer;