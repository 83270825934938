import React from 'react'; 
import {connect} from "react-redux"; 
import {tick, setstateInterval} from "./timerSlice";

function Timer ({minutes, seconds, active, interval, tick, setstateInterval}) { 
    if (active && (interval == null) ) {
        setstateInterval(setInterval(tick, 1000))
    }

    return (
        
        <h1> { minutes }:{ seconds < 10 ? `0${ seconds }` : seconds } </h1> 

    )
}


const mapStateToProps = state => ({
    seconds: state.timer.seconds,
    minutes: state.timer.minutes, 
    interval: state.timer.interval, 
    active: state.timer.active

})
const mapDispatchToProps = {tick, setstateInterval}

export default connect(mapStateToProps, mapDispatchToProps)(Timer); 