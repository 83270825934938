import {createSlice} from "@reduxjs/toolkit"; 


export const wallSlice = createSlice({
    name: "wall", 
    initialState: { 
        answer: ""
    }, 
    reducers: { 
        changeAnswer: (state, action) => {
            console.log(state.answer)
            console.log(action.payload)
            state.answer = action.payload; 
        }
    }

})

export const {changeAnswer} = wallSlice.actions; 

export default wallSlice.reducer;