import React, {useState} from "react"; 
import {Container, Row, Col, Pagination, Button} from "react-bootstrap"; 
import { SwitchTransition, CSSTransition} from "react-transition-group"
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; 
import letter1 from "../photos/letters/letter1.png";
import letter2 from "../photos/letters/letter2.png";
import letter3 from "../photos/letters/letter3.png";
import letter4 from "../photos/letters/letter4.png";
import letter5 from "../photos/letters/letter5.png";
import redwedding from "../photos/redwedding.jpg";
import finalclue from "../photos/finalclues/fclue3.png"; 
import { faArrowRight, faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import "./redwedding.css";
import socket from "../socket"; 

function RedWedding({solved, globanswer}) { 
    const [number, setNumber] = useState(0);
    const [answer, setAnswer] = useState(""); 
    const [page, changePage] = useState(0); 

    function Submit() { 
        if (answer.toLowerCase() == "mormont") { 
            socket.emit("solved", 1)
        } else{ 
            setAnswer("")
        }
    }

    function changeAnswer(value) { 
        setAnswer(value);
        socket.emit("wedding", {value})
    }

    const letters = [letter1, letter3, letter4, letter2, letter5]
    const intro = (<div className = "script"> <p> The Red Woman can sense your pain, as she watches over your cold, souless body. But you are far
    from your redemption. Jon Snow. You truly are a worthless bastard; so worthless you 
    couldn’t even protect your family.</p> 
            <p> 
            ah yes, here we are... Walder Frey and his friendly, humble abode. The final breaths of our King in the North, and a feast of trauma for our young Arya’s eyes. 
            Can you save another Northern Family fom their demise, or are you getting comfortable here in the darkness of hell?
            </p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ZnxvUuSzbMI" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
             </div> )
    
    const pages = Array(5).fill("").map((_, index) => {

        return (
            <Pagination.Item key = {index} active = {page == index} onClick = {()=> changePage(index)}>
                {index + 1}
            </Pagination.Item>
        )
    })
    const puzzle = ( 
        <div className = "puzzle">
            <img src ={letters[page]} />
            <Pagination>
                {pages}
            </Pagination>
        </div>)

    const instructions = (
        <div className = "instructions">
            <h1> The Next Victims </h1>
            <p> It appears that the Frey's have left some type of message in their correspondence to one of  the smaller Northern families about their
                next target to seize more power in the North. You must prove your common sense is not gone and solve this to prove your redemption. 
                You've also been given the following information that was swiped from Walder Frey himself. 
            </p>
            <li> Percival's middle son's niece in law </li>
            <li> The newly wed bride </li>
            <li> Percival's oldest son </li>
            <li> The oldest granddaughter of Elaehna </li>
            <li> Ronald's youngest son </li>
            <li> The son in law of the middle child of Percival </li>
            <li> The youngest of the only twins in the family </li> 
            <div className = "input">
            <input 
                type = "text" 
                value = {globanswer}
                onChange = {(event)=> changeAnswer(event.target.value)}
            />
            <Button onClick = {()=> Submit()}> Submit </Button>
        </div>
        </div>
    )
    const slides = [intro, instructions, puzzle]
    if (solved[1]) {
        return (
        <Container className = "base wedding" fluid style = {{backgroundImage: `url(${redwedding})`}}>
        <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
         <div className = "finalclue">
             <img src = {finalclue} />
        </div>
        </Container>
        )
    } else { 
    return ( 
        <Container className = "base wedding" fluid style = {{backgroundImage: `url(${redwedding})`}}>
            <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
           <SwitchTransition mode = "out-in">
                <CSSTransition
                    key = {number}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                      }}
                    classNames = "fade"
                >
                    <div>
                    {
                        (number != 0) && <Button className = "arrow left" onClick = {() => setNumber(number - 1)}> <FontAwesomeIcon icon = {faArrowLeft} /> </Button>
                    }
                    {
                        (number != 2) && <Button className = "arrow right" onClick = {() => setNumber(number + 1)}> <FontAwesomeIcon icon = {faArrowRight} /> </Button>
                    }
                 {slides[number]}
                    </div>
                    </CSSTransition> 
           </SwitchTransition>
        </Container>
    )
}


}; 

const mapStateToProps = state => ({
    solved: state.login.solved, 
    globanswer: state.wedding.answer
})


export default connect(mapStateToProps, null)(RedWedding); 