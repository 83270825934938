import React, {useEffect} from "react"; 
import {connect} from "react-redux"; 
import {Link, useHistory} from "react-router-dom"
import {Container, Row, Col, Button} from "react-bootstrap"; 
import {start} from "../timer/timerSlice"; 
import "./home.css"; 
import HomePage from "../photos/hell.jpg";
import { entered } from "../login/loginSlice";


function Home({users, room,enter, start, entered}) { 

    const history = useHistory(); 
    const teammates = users.map(user => <li> {user.name} </li>)


    useEffect(() => { 
        if (room === "") { 
            history.push("/")
        }
    }, [])

    function Entering() { 
        start()
        entered()
    }

    return ( 
        <Container className = "base home" fluid style = {{backgroundImage: `url(${HomePage})`}}>
         
            <h1> You know NOTHING, Jon Snow! </h1>
            <h1> Welcome to Purgatory </h1>  

            <Row>
                <Col md = {6}>
                    <div className = "notification"> 
                    The gods, old and new, have had a tough time deciding your fate. 
                    You were destined to play a great role in the  fight in the mortal realm,
                     but we are not sure if that destiny holds anymore. You must prove your worth 
                     once more to deserve another chance...
                    </div>
                </Col>
                <Col md = {6}>
                    <div className = "teammates">
                        <h3> Teammates in Room <span id = "roomid"> {room} </span> </h3>
                        {teammates}
                    </div>
                </Col>
            </Row>
          {!enter && <Link to= "/doors"> <Button onClick = {() => Entering()}> Enter </Button> </Link> }
            {enter && <Link to= "/doors"> <Button> Return </Button> </Link>}
        </Container>
    )
}

const mapStateToProps = state => ({
    users: state.login.users, 
    room: state.login.room, 
    enter: state.login.enter
})

const mapDispatchToProps = {start, entered}

export default connect(mapStateToProps, mapDispatchToProps)(Home);