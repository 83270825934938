import { createSlice} from "@reduxjs/toolkit"; 


export const timerSlice = createSlice({ 
    name: "timer", 
    initialState: {
        minutes: 60, 
        seconds: 0, 
        interval: null, 
        over: false,
        active: false
    }, 
    reducers: { 
        start: (state) =>  {
            state.active = true
        }, 
        tick: (state) =>  {
            if (state.seconds > 0) { 
                state.seconds -= 1 
            } 
            if (state.seconds === 0) { 
                if (state.minutes === 0) { 
                    state.over = true
                } else { 
                    state.minutes -= 1 
                    state.seconds = 59
                }
            }
        }, 
        setstateInterval: (state, action) => {
            state.interval = action.payload
        }, 
        resetInterval: (state) => { 
            clearInterval(state.interval);
            state.interval = null
        }, 
        resetTime: (state) => { 
            state.minutes = 60; 
            state.seconds = 0; 
            state.over = false; 
            
        }
    }
})

export const {start, tick, setstateInterval, resetTime, resetInterval} = timerSlice.actions; 

export default timerSlice.reducer;