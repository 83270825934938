import React, {useState} from "react"; 
import {Container, Button} from "react-bootstrap"
import {connect} from "react-redux"; 
import socket from "../socket"; 
import {Link, useHistory} from "react-router-dom"; 
import {faArrowUp} from "@fortawesome/free-solid-svg-icons" 
import decrypt from "../photos/finalclues/dothrakialphabet.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import "./final.css"; 



function Final({globanswer}) { 
    const [answer, setAnswer] = useState("")
    const history  = useHistory(); 
    function changeAnswer(value) { 
        socket.emit("final", {value})
        setAnswer(value); 
    } 

    function Submit() { 
        console.log(globanswer.toLowerCase())
        if (globanswer.toLowerCase() == "asshekhqoyi vezhvena") { 
            history.push("/congrats");
        } else if (globanswer.toLowerCase() == "asshekhqoyi vezhvena soham") { 
            history.push("/congratsspecial");
        } else { 
            socket.emit("final", {value: ""})
        }
    }

    return ( 
        <Container className = "base final">
             <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
            <div className = "password">
                
                <img src = {decrypt} /> 
                <input 
                    type = "text"
                    value = {globanswer}
                    onChange = {(event) => changeAnswer(event.target.value)}
                />
                            <Button onClick = {()=> Submit()}> Submit </Button>
            </div>
        </Container>
    )
}

const mapStateToProps = state => ({
    globanswer: state.final.answer
})
export default connect(mapStateToProps, null)(Final); 