import React, {useState} from "react"; 
import {Container, Row, Col, Button} from "react-bootstrap"; 
import { SwitchTransition, CSSTransition} from "react-transition-group"
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import wall from "../photos/sacrifice.jpg";
import battlescroll from "../photos/battlescroll.png"; 
import finalclue from "../photos/finalclues/fclue4.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; 
import { faArrowRight, faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import "./sacrifice.css";
import socket from "../socket"; 

function Sacrifice({globanswer, solved}) { 
    const [number, setNumber] = useState(0);
    const [answer, setAnswer] = useState(""); 

    function Submit() { 
        if (answer.toLowerCase() == "chaos is a ladder") { 
            socket.emit("solved", 3)
        } else{ 
            setAnswer("")
        }
    }

    function changeAnswer(value) { 
        socket.emit("sacrifice", value); 
        setAnswer(value); 
    }

    const intro = (<div className = "script"> <p> 
        Have you no sense to separate love and duty? Have you learned NOTHING, Jon Snow - your good conscious and righteousness is not for the 7 kingdoms; 
        nor does it deem you fit, despite the rightful heir to the Iron Throne. Too much remorse. Remember your “action” here: If you are ever to rise to the occasion 
        of being able to reclaim the throne promised to Aegon Targaryen, then you must learn to look past the individual components of war and nobility.  
        You must stop considering your feelings and trust only in yourself and what you see. If not, you risk the same fate that seems to lie in your path everytime that you chose to 
        stray away from this. 
            </p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/-j_Hk4TElsA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> )
    const puzzle = ( 
        <div className = "puzzle">
        <img src = {battlescroll}/> 
        <div className = "input">
            <input 
                type = "text" 
                value = {globanswer}
                onChange = {(event)=> changeAnswer(event.target.value)}
            />
            <Button onClick = {()=> Submit()}> Submit </Button>
        </div>
        </div>)

    const slides = [intro, puzzle]
    if (solved[3]) { 
        return (
        <Container className = "base sacrifice" fluid style = {{backgroundImage: `url(${wall})`}}>
        <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
         <div className = "finalclue">
             <img src = {finalclue} />
        </div>
        </Container>)
    } else { 
    return ( 
        <Container className = "base sacrifice" fluid style = {{backgroundImage: `url(${wall})`}}>
            <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
           <SwitchTransition mode = "out-in">
                <CSSTransition
                    key = {number}
                    addEndListener={(node, done) => {
                        node.addEventListener("transitionend", done, false);
                      }}
                    classNames = "fade"
                >
                    <div> 
                    <Button className = {number == 0 ? "arrow right" : "arrow left"} onClick = {()=> setNumber(1 - number)}>
                            {number == 0 ? <FontAwesomeIcon icon = {faArrowRight} /> : <FontAwesomeIcon icon = {faArrowLeft} />}
                    </Button>
                        {slides[number]}
                    </div>
                    </CSSTransition> 
           </SwitchTransition>
        </Container>
    )
    }
}; 

const mapStateToProps = state => ({
    globanswer: state.sacrifice.answer, 
    solved: state.login.solved
})


export default connect(mapStateToProps, null)(Sacrifice); 