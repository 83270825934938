import {createSlice} from "@reduxjs/toolkit"; 


export const finalSlice = createSlice({
    name: "final", 
    initialState: { 
        answer: ""
    }, 
    reducers: { 
        changeAnswer: (state, action) => {
            state.answer = action.payload; 
        }
    }

})

export const {changeAnswer} = finalSlice.actions; 

export default finalSlice.reducer;