import React, {useState} from "react"; 
import {Container, Row, Col, Button, Table} from "react-bootstrap"; 
import { SwitchTransition, CSSTransition} from "react-transition-group"
import { connect } from "react-redux";
import {Link} from "react-router-dom";
import wall from "../photos/BattleBastard.jpg";
import finalclue from "../photos/finalclues/fclue1.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; 
import { faArrowRight, faArrowLeft, faArrowUp } from "@fortawesome/free-solid-svg-icons"
import "./bastardbattle.css";
import socket from "../socket"; 

function BastardBattle({gridState, solved}) { 
    const [number, setNumber] = useState(0);
    const [answer, setAnswer] = useState(""); 

    function Submit() { 
        if (answer.toLowerCase() == "jade") { 
            socket.emit("solved", 2)
        } else{ 
            setAnswer("")
            // eject the person
        }
    }

    function handleChartChange(value, elem, num) {
        socket.emit("chartChange", {value, elem, num})
    }; 

    
    const intro = (<div className = "script"> <p>
        Melisandre seems to feel some power within you, but have you forgotten what Tyrion has taught you, you fool? 

        
    <blockquote> Let me give you some counsel, bastard. Never forget what you are, for surely the world will not. 
    Make it your strength. Then it can never be your weakness. Armor yourself in it, and 
    it will never be used to hurt you. </blockquote>

    Yet look at what you’ve done to poor Rickon. </p>
    
    <iframe width="560" height="315" src="https://www.youtube.com/embed/HIBVYaXPK5k" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            
            </div> )
    const grid = ["Jon", "Brienne", "Syrio", "Lyanna", "Gold", "Ruby", "Silver", "Jade"].map((elem, index2) => {
        const pieces = Array(8).fill("").map((_, index) => <td className = {index == 4 ? "divider": "normal"}> <input value = {gridState[elem][index]} maxLength = "1" type = "text" onChange = {(event) => {handleChartChange(event.target.value, elem, index)} } /> </td>)
        

        return ( 
            <tr className = {(index2 == 4) ? "divider" : "normal"}> 
                <td className = "titlerow"> {elem} </td>
                {pieces}
            </tr>
        )
    })
    const head = ["", "Valyrian", "Wood", "Steel", "Iron", "Mereen", "Free Folk", "King's Landing", "The North"].map(elem => <td> {elem} </td>)

    const puzzle = ( 
        <div className = "puzzle">
            <Table variant= "dark">
                <thead>
                    {head}
                </thead>
                <tbody>
                {grid}
                </tbody>
            </Table>
        </div>)
    const instructions = (
        <div className = "instructions">
            <li> The owner of the iron sword pledges allegiance to The North </li>
            <li> Jon would never pledge allegiance to Mereen or the King </li>
            <li> The sword set with Jade belongs to a supporter of either the Freefolk or the Mereen </li>
            <li> The owner of the steel sword pledges allegiance to the Free Folk </li>
            <li> The owner of the gold decorated sword doesn't pledge allegiance to the King </li>
            <li> Lyanna hates wooden swords </li>
            <li> The owner of the woord sword pledges allegiance to the King </li>
            <li> The steel sword has silver embroidery and the wood sword has a jade hilt </li>
            <li> Syrio has a sword with decorations of ruby </li>
            <div className = "input">
            <input 
                type = "text" 
                placeholder = "Brienne's Sword Decoration"
                value = {answer}
                onChange = {(event)=> setAnswer(event.target.value)}
            />
            <Button onClick = {()=> Submit()}> Submit </Button>
            </div>
        </div>

    )

    const slides = [intro, instructions, puzzle]
    if (solved[2]) { 
        return (
        <Container className = "base battle" fluid style = {{backgroundImage: `url(${wall})`}}>
           <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
            <div className = "finalclue">
                <img src = {finalclue} />
            </div>
        </Container>
        )
    } else {
    return ( 
            <Container className = "base battle" fluid style = {{backgroundImage: `url(${wall})`}}>
                <Link to="doors"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
            <SwitchTransition mode = "out-in">
                    <CSSTransition
                        key = {number}
                        addEndListener={(node, done) => {
                            node.addEventListener("transitionend", done, false);
                        }}
                        classNames = "fade"
                    >
                        <div>
                        {
                            (number != 0) && <Button className = "arrow left" onClick = {() => setNumber(number - 1)}> <FontAwesomeIcon icon = {faArrowLeft} /> </Button>
                        }
                        {
                            (number != 2) && <Button className = "arrow right" onClick = {() => setNumber(number + 1)}> <FontAwesomeIcon icon = {faArrowRight} /> </Button>
                        }
                    {slides[number]}
                        </div>
                        </CSSTransition> 
            </SwitchTransition>
            </Container>
        )
    }

}; 

const mapStateToProps = state => ({ 
    gridState: state.battle.grid, 
    solved: state.login.solved
})

export default connect(mapStateToProps, null)(BastardBattle); 