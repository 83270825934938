import React, {useState} from "react"; 

import {Accordion, Card, Modal, Button, Col, Container,Row} from 'react-bootstrap';
import "./raven.css"; 


function Hints() { 
    
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const values = [
        {
            "num": 12, 
            "title": "Beyond the Wall One", 
            "body": " The image translates into English letters - do you see any common properties across all the letters? "
        },
        {
            "num": 1, 
            "title": "Beyond the Wall Two", 
            "body": "You can notice that all the letters have both vertical and horizontally symmetry"  , 
        }, 
        {
            "num": 2, 
            "title": "Beyond the Wall Answer", 
            "body": "The letters, once extracted, should seemingly spell 'What is coming' - i.e Winter "
        }, 
        {
            "num": 3, 
            "title": "Red Wedding One", 
            "body": "Create a Family Tree Using the Provided Information - assume the simplest possible family tree given the information "
        }, 
        {
            "num": 4, 
            "title": "Red Wedding Two ", 
            "body": "The GrandFather of the family is Percival, his wife is Elaehna. They have 3 sons" 
        }, 
        {
            "num": 5, 
            "title": "Red Wedding Answer", 
            "body": " Mormont  "
        }, 
        {
            "num": 6, 
            "title": "Battle of the North One", 
            "body": " Highly recomend using the chart and mapping out all the information you have. Not much help can be provided here "
        }, 
        
        {
            "num": 7, 
            "title": "Battle of the North Answer ", 
            "body": " Jade "
        }, 
        {
            "num": 8, 
            "title": " Sacrifice One", 
            "body": " Injuries are dotted across the warzone but deaths are dashes of true war"
        }, 
        {
            "num": 9, 
            "title": "Sacrifice Two", 
            "body": " The best way of approaching this is to solve for each of the records for each family individually and then finding a logically way of organizing the extracted letters "
        }, 
        {
            "num": 10, 
            "title": "Sacrifice Three", 
            "body": " Use Morse Code and organize by the family, in the order they appear "
        }, 
        {
            "num": 11, 
            "title": "Sacrifice Answer", 
            "body": " Chaos is a ladder "
        }, 
        
    ]

    const hinters = values.map((elem) => { 
        return (
            
            <Card key = {elem.toString()}>
                <Card.Header>
                <Accordion.Toggle as={Button} className = "hinttitle" variant="link" eventKey={elem.num}>
                    {elem.title}
                </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={elem.num}>
                <Card.Body> {elem.body} </Card.Body>
                </Accordion.Collapse>
             </Card>
            
        )
    })
    return ( 
        <div class = "hints"> 
              <Button variant="primary" id = "Three Eyed Raven" onClick={handleShow}>
           Three Eyed Raven
        </Button>
            <Modal show = {show} size = "lg" onHide = {handleClose}> 
                <Modal.Header>
                    <h3> The Three Eyed Raven Sees All </h3>
                </Modal.Header>
                <Modal.Body>
                    <Accordion> 
                        {hinters}

                    </Accordion>
                </Modal.Body>
            </Modal>
        </div> 

    )
}


export default Hints;