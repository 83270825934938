import React from "react"; 
import {connect} from "react-redux"; 
import {Link} from "react-router-dom"; 
import {Container, Row, Col} from 'react-bootstrap'; 
import Timer from "../timer/timer"; 
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"; 
import {faArrowUp} from "@fortawesome/free-solid-svg-icons"
import DoorBackground from "../photos/doorbackground.jpeg";
import Hints from "../raven/raven.js";
import "./doors.css";


function Doors() { 


    return(
        <Container className = "base doors" fluid style = {{backgroundImage: `url(${DoorBackground})`}}>
            <Link to="home"> <FontAwesomeIcon icon = {faArrowUp} size={"3x"} className = "arrow up"/> </Link>
            <Link to = "beyondthewall"> 
            <div className = "door one">
                <span className = "knob"> </span>
            </div>
            </Link>
            <Link to = "redwedding"> 
            <div className = "door two">
                <span className = "knob"> </span>
            </div>
            </Link>
            <Link to = "bastardbattle"> 
            <div className = "door three">
                <span className = "knob"> </span>
            </div>
            </Link>
            <Link to = "sacrifice"> 
            <div className = "door four">
                <span className = "knob"> </span>
            </div>
            </Link>
            <Link to = "final"> 
            <div className = "door main">
                <span className = "knob"> </span>
            </div>
            </Link>
            <div className = "timer">
                <Timer/>
            </div>
            <div className = "raven">
                <Hints/>
            </div>
        </Container>
    )


}


export default connect()(Doors)