import { createSlice } from '@reduxjs/toolkit';

export const battleSlice = createSlice({
  name: 'battle',
  initialState: {
    grid: {
        "Jon": Array(8).fill(""), 
        "Brienne": Array(8).fill(""), 
        "Syrio": Array(8).fill(""), 
        "Lyanna": Array(8).fill(""), 
        "Gold": Array(8).fill(""), 
        "Ruby": Array(8).fill(""), 
        "Jade": Array(8).fill(""), 
        "Silver": Array(8).fill("")
    }
  },
  reducers: {
    changeGrid: (state, action) => { 
        state.grid[action.payload.elem][action.payload.num] = action.payload.value; 
         
    }    
  },
});


export const { changeGrid } = battleSlice.actions;



export default battleSlice.reducer;
