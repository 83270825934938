import React from "react"; 
import {Container, Row, Col} from "react-bootstrap"; 
import Valar from "../photos/loginscreen.jpg";
import "./congrats.css";


function Congrats() { 



    return ( 
        <Container className = "base congrats" fluid style = {{backgroundImage: `url(${Valar})`}}>
            <Row>
                <Col md = {6}>
                <div className = "script">
                <p>
                ASSHEKHQOYI VEZHVENA literally means "Great Day of Blood" in Dothraki, but for the Westerosi it might be 
                more accurately interpreted as "Happy Birthday". This is what today now is, another day of rebirth for you. 
                </p>
               <p>
                   Congrats on proving that you are capable of fulfilling your destiny and returning to the mortal world. 
                We will watch your progress with great attention and anticipation Azor Ah --- Jon Snow. We wish you the best of 
                luck and we hope that we do not see you for a long time. 
                </p> 
                <p>
                    We ask that before you leave you fill out the exit survey to the right, as well as potentially check out other 
                    purgatories <a href = "https://escapeit.xyz"> here </a>. 

                </p>
            </div>
                </Col>
                <Col md = {6}>
                    <iframe title="Feedback" src="https://docs.google.com/forms/d/e/1FAIpQLSc9Q5I3lhL90Hu-CGc1oEOcyPu_FTcJln_hGXbVJAzz03diKQ/viewform?embedded=true" width="640" height="1519" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
                
                </Col>
            </Row>

            
        </Container>
    )
}


export default Congrats; 