import React, {useState} from "react"; 
import {connect} from"react-redux"; 
import {useHistory} from "react-router-dom"; 
import {Container, Row, Col, Button} from "react-bootstrap";
import "./login.css";
import socket from "../socket";
import {setName, setRoom} from "./loginSlice"
import Valar from "../photos/valar.jpg"


function Login({setRoom, setName}) { 
    const [name, changeName] = useState("")
    const [room, changeRoom] = useState("")
    const history = useHistory();

    function joinTeam() { 
        if (room.length != 7 || name.length == 0) { 
            alert("The length of the room must be 7 letters long")
        } else if(name.length == 0) {
            alert('You must enter a name')
        } else { 
            setName(name)
            setRoom(room);
            socket.emit('join', {name, room, status: false}, (valid) => { 
                if (valid) { 
                    history.push("/home"); 
                } else { 
                    alert("Not a valid room")
                }
            })
        }
    }

    function createTeam() { 
        if (name.length == 0) { 
            alert("You must enter a name")
        } else {
            const roomName = Math.random().toString(36).substring(2, 9)
            setRoom(roomName);
            setName(name)
            socket.emit('join', {name, room: roomName, status: true}, (valid) => { 
                history.push("/home");
            })
        }
    }

    return ( 
        <Container className = "base login" fluid style={{backgroundImage: `url(${Valar})` }}>
            <Row>
                <Col md= {12}>
                    <div className = "name">
                        <input 
                            type = "text"
                            placeholder = "Name"
                            value = {name}
                            onChange = {e => changeName(e.target.value)}
                        />
                    </div>

                </Col>
            </Row>
            <Row>
                <Col sm = {12} md = {6}>
                    <Button onClick = {createTeam}> Create New </Button>
                </Col>
                <Col sm = {12} md = {6}>
                    <div >
                        <div className = "enter"> 
                            <Button className = "enterroom" onClick = {joinTeam}> Enter Room </Button>
                        </div>
                        <input 
                            type = "text"
                            value = {room}
                            placeholder = "Room Name"
                            onChange = {e => changeRoom(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

const mapDispatchToProps = {setRoom, setName}
const mapStateToProps = []



export default connect(null, mapDispatchToProps)(Login); 