import React, {useEffect} from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom"; 
import {connect} from "react-redux"; 
import {useHistory} from "react-router-dom"; 
import socket from "./components/socket";
import {setSolved, setUsers, } from "./components/login/loginSlice";
import {changeAnswer as WallAnswer} from "./components/BeyondWall/BeyondWallSlice"; 
import {changeAnswer as SacrificeAnswer} from "./components/sacrifice/sacrificeSlice"; 
import {changeAnswer as FinalAnswer} from "./components/final/finalSlice"; 
import {changeAnswer as WeddingAnswer } from "./components/RedWedding/redweddingSlice"; 
import {changeGrid} from "./components/BastardBattle/bastardbattleSlice"; 
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from "./components/login/login";
import Home from "./components/home/home"; 
import Doors from "./components/doors/doors"; 
import BeyondWall from "./components/BeyondWall/BeyondWall";
import RedWedding from "./components/RedWedding/redwedding";  
import BastardBattle from "./components/BastardBattle/bastardbattle"; 
import Congrats from "./components/congrats/congrats";
import CongratsSpecial from "./components/congrats/congratsspecial";
import Sacrifice from "./components/sacrifice/sacrifice"; import Final from "./components/final/final"; 


function App({setSolved, name, room, enter, setUsers, WallAnswer, changeGrid, SacrificeAnswer, FinalAnswer, WeddingAnswer}) {
  

  useEffect(() => {

    setInterval(() => {
      socket.emit("check", () => { 
        socket.emit("join", {name, room, status: false}, (valid) => { 
          if (!valid && enter) { 
            alert("an error happened, please try relogging into a new room");
            window.location.href = "https://asoiaf.escapeit.xyz"
          }
        })
      }); 
    }, 60000)

    socket.on("roomData", ({users, solved}) => { 
      setSolved(solved); 
      setUsers(users); 
    })
    
    socket.on("wall", ({value}) => { 
      WallAnswer(value); 
      console.log(value)
    }) 
    socket.on("sacrifice", ({value}) => { 
      SacrificeAnswer(value); 
    }) 
    socket.on("final", ({value}) => { 
      FinalAnswer(value); 
    }) 
    socket.on("wedding", ({value})=> { 
      WeddingAnswer(value); 
    })

    socket.on("chartChange", ({value, num, elem}) => { 
      changeGrid({value,num, elem})
    })

    socket.on("solved", ({num}) => { 
      setSolved(num)
    })
  }, [])

  return (
      <BrowserRouter>
          <Switch>
            <Route path= "/" exact render = {() => <Login/>} /> 
            <Route path= "/home" exact render= {() => <Home/> } /> 
            <Route path= "/doors" exact render= {()=> <Doors/>} />
            <Route path= "/beyondthewall" exact render = {()=> <BeyondWall/>} />
            <Route path= "/redwedding" exact render = {()=> <RedWedding/>} /> 
            <Route path= "/bastardbattle" exact render={()=> <BastardBattle/>}/>
            <Route path= "/sacrifice" exact render = {() => <Sacrifice/>} /> 
            <Route path= "/final" exact render = {()=> <Final/> } /> 
            <Route path= "/congrats" exact render = {()=> <Congrats/> } /> 
            <Route path= "/congratsspecial" exact render = {() => <CongratsSpecial/>}/>
          </Switch>
      </BrowserRouter>
    )
  ;
}

const mapStateToProps = state => ({
  enter: state.login.enter, 
  name: state.login.name, 
  room: state.login.room
})

const mapDispatchToProps = {setSolved, setUsers, WallAnswer, changeGrid, WeddingAnswer, SacrificeAnswer, FinalAnswer}

export default connect(mapStateToProps, mapDispatchToProps)(App);
